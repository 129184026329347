import request from '@/utils/request'

// 一级地区列表(国家)
export function getLevel1AlRegions(query) {
    return request({
        url: 'common/getLevel1AlRegions',
        method: 'get',
        params: query
    })
}

//根据地区编码获取其下属地区
export function getAlRegionsByCode(query) {
    return request({
        url: 'common/getAlRegionsByCode',
        method: 'get',
        params: query
    })
}

//根据地区ID获取其下属地区
export function getAlRegionsByID(query) {
    return request({
        url: 'common/getAlRegionsByID',
        method: 'get',
        params: query
    })
}

// 验证安置人市场个数
export function miMemberRightNum(query) {
    return request({
        url: 'common/miMemberRightNum',
        method: 'get',
        params: query
    })
}

// 验证用户编号
export function getPersonInfo(query) {
    return request({
        url: '/common/personInfo',
        method: 'get',
        params: query
    })
}

// 首页推荐商品
export function getRecommendGoods(query) {
    return request({
        url: '/common/getRecommendGoods',
        method: 'get',
        params: query
    })
}

// 经销商轮播图
export function memberCarousel(query) {
    return request({
        url: '/common/memberCarousel',
        method: 'get',
        params: query
    })
}

// 欧亚8月促销  2022年7月29日-2022年8月28日
export function RUSAugustPromotion(query) {
    return request({
        url: '/common/RUSAugustPromotion',
        method: 'get',
        params: query
    })
}

// 代办处列表  代办处
export function agentListByCompanyCode(query) {
    return request({
        url: '/common/agentListByCompanyCode',
        method: 'get',
        params: query
    })
}
