<!--文件中文名:经销商注册分享 -->
<template>
  <div class="MemberRegisterShare app-container">
    <el-form
        ref="registerRef"
        :model="registerInfo"
        label-width="auto"
        size="mini"
        style="text-align: left"
    >
      <!-- 基本信息 -->
      <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
      <!-- 推荐人编号 -->
      <el-form-item
          :label="$t('mi.sellerNo') + ':'"
          required
      >
        <el-input
            @input="value=>registerInfo.recommendNo=value.toUpperCase()"
            v-model="registerInfo.recommendNo"
            clearable
            style="width: 80%"
            @blur="recommendNoBlur($event.target.value)"
        />
        <span
            :class="{ account_name_css: recommendName_span_show }"
            class="red-span"
        >{{ recommendName_span }}</span>
      </el-form-item>
      <!-- 安置人编号 -->
      <el-form-item
          :label="$t('mi.servicerNo') + ':'"
          required
      >
        <el-input
            @input="value=>registerInfo.linkNo=value.toUpperCase()"
            v-model="registerInfo.linkNo"
            clearable
            style="width: 80%"
            @blur="tmpLinkNoBlur($event.target.value)"
        />
        <span
            :class="{ account_name_css: tmpLinkNo_span_show }"
            class="red-span">
          {{ tmpLinkNo_span }}
        </span>
        <br/>
      </el-form-item>

      <!-- 位置 -->
      <el-form-item :label="$t('sys.Location')" required>
        <el-radio-group v-model="registerInfo.lr">
          <el-radio
              :disabled="disabled1"
              label="0"
              @change="changRadio($event)"
          >{{ $t('common.left') }}
          </el-radio
          >
          <el-radio
              :disabled="disabled2"
              label="1"
              @change="changRadio($event)"
          >{{ $t('common.right') }}
          </el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-bottom: 10px">
      <!-- 生成链接二维码 -->
      <el-button
          v-loading="doLoading"
          size="mini"
          type="primary"
          @click="makeUrl"
      >
        {{ this.$t('Generate.link.QR_code') }}
      </el-button>
      <el-button
          v-loading="doLoading"
          size="mini"
          type="success"
          v-show="qrCodeShow"
          @click="downloadQRCode"
      >
        {{ this.$t('Download.QR_code') }}
      </el-button>
      <el-button
          v-loading="doLoading"
          size="mini"
          type="success"
          v-show="qrCodeShow"
          @click="copyQRCode"
      >
        {{ this.$t('CopyUrl') }}
      </el-button>
    </div>
    <div v-show="qrCodeShow" id="qrcode" ref="qrcode" style="display: flex;justify-content: center;margin-top: 20px"/>
    <div v-show="qrCodeShow" v-text="QRCodeText" style="margin-top: 10px"/>
  </div>
</template>

<script>

import {miMemberInfo2} from "@/api/userInfo/userInfo"
import {miMemberRightNum} from "@/api/common/common"
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'

export default {
  name: 'MemberRegisterShare',
  data() {
    return {
      qrCodeShow: false,
      doLoading: false,
      recommendName_span_show: false,
      tmpLinkNo_span_show: false,
      recommendName_span: '',
      tmpLinkNo_span: '',
      tmpLinkNo_num_span: '',
      memberNo_span: '',
      disabled1: '',
      disabled2: '',
      qr: null,
      BaseUrl: null,
      registerInfo: {
        recommendNo: null,
        linkNo: null,
        lr: null
      },
      QRCodeText: ''
    }
  },
  mounted() {
    this.BaseUrl = window.location.href.split('#')[0]
  },
  methods: {
    copyQRCode() {
      navigator.clipboard.writeText(this.QRCodeText).then(() => {
        this.$message.success(this.$t('CopySuccess').toString())
      })
    },
    recommendNoBlur(val) {
      if (val) {
        this.doLoading = true
        miMemberInfo2({code: val})
            .then((res) => {
              this.doLoading = false
              this.recommendName_span = res.data.name
              this.recommendName_span_show = false
            })
            .catch(() => {
              this.doLoading = false
              this.recommendName_span = ''
              this.recommendName_span_show = true
            })
      } else {
        this.recommendName_span = ''
        this.recommendName_span_show = false
      }
    },
    tmpLinkNoBlur(val) {
      this.registerInfo.lr = ''
      if (val) {
        this.doLoading = true
        miMemberRightNum({memberNo: val})
            .then((res) => {
              this.doLoading = false
              if (res.data.leftMem == null || res.data.leftMem == '') {
                this.disabled1 = false
              }
              if (res.data.rightMem == null || res.data.rightMem == '') {
                this.disabled2 = false
              }
              this.tmpLinkNo_span = res.data.name
              this.tmpLinkNo_num_span = res.data.num
              this.tmpLinkNo_span_show = false
            })
            .catch(() => {
              this.doLoading = false
              this.tmpLinkNo_span = ''
              this.tmpLinkNo_num_span = ''
              this.tmpLinkNo_span_show = true
            })
      } else {
        this.tmpLinkNo_span = ''
        this.tmpLinkNo_num_span = ''
        this.tmpLinkNo_span_show = false
      }
    },
    changRadio(val) {
      this.registerInfo.lr = val
    },
    makeUrl() {
      if (!this.registerInfo.recommendNo) {
        return this.$message.error(this.$t('label.pleaseFillIn').toString() + this.$t('mi.sellerNo').toString())
      }
      if (!this.registerInfo.linkNo) {
        return this.$message.error(this.$t('label.pleaseFillIn').toString() + this.$t('mi.servicerNo').toString())
      }
      if (!this.registerInfo.lr) {
        return this.$message.error(this.$t('label.pleaseFillIn').toString() + this.$t('sys.Location').toString())
      }
      this.qrCodeShow = false
      if (this.qr) {
        this.$refs.qrcode.innerHTML = ''
      }
      this.doLoading = true
      this.QRCodeText = this.BaseUrl + '#/memberRegister-noLogin?recommendNo=' + this.registerInfo.recommendNo + '&linkNo=' + this.registerInfo.linkNo + '&lr=' + this.registerInfo.lr
      console.log(this.QRCodeText, 'QRCodeText')
      this.qr = new QRCode('qrcode', {
        width: 150,
        height: 150, // 高度
        text: this.QRCodeText // 二维码内容
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'
        // foreground: '#ff0'
      })
      this.doLoading = false
      this.qrCodeShow = true
    },
    downloadQRCode() {
      html2canvas(document.getElementById('qrcode')).then(canvas => {
        this.imgUrl = canvas.toDataURL('image/png') // 可将 canvas 转为 base64 格式
      })
      this.downloadIamge(this.imgUrl, 'pic')
    },
    downloadIamge(imgsrc, name) { // 下载图片地址和图片名
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    }
  }
}

</script>

<style lang="scss" scoped>

.account_name_css:after {
  content: 'X';
  color: #ff4949;
}

.red-span {
  color: red;
}

.register_3_css {
  display: flex;

  ::v-deep .el-form-item {
    margin-bottom: 0
  }
}
</style>
